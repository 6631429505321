<template>
  <div>
    <page-header :items="items" />
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <ElLabel
                for-label="title"
                :title="$t('Title')"
                :is-required-icon="true"
              />
              <input
                id="title"
                v-model="form.title"
                name="title"
                class="form-control"
                :disabled="btnLoading"
                type="text"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <MultiSelectInput
                :showLabels="true"
                v-model="form.assignee"
                :options="users"
                :key="form.assignee"
                :multiple="true"
                :textLabel="$t('Assignee')"
                trackBy="id"
                class=""
                moduleName="users"
                :search-param-name="'search'"
                :customLabel="customLabel"
                :disabled="btnLoading"
              >
                <template #beforeList>
                  <div
                    class="p-1"
                    style="
                      display: grid;
                      gap: 2px;
                      grid-template-columns: 25% 25% 50%;
                    "
                  >
                    <p class="font-bold">
                      {{ $t("First Name") }}
                    </p>
                    <p class="font-bold">
                      {{ $t("Last Name") }}
                    </p>
                  </div>
                </template>
                <template #singleLabel="{ props }">
                  <p>
                    {{ props.option.first_name }}
                    {{ props.option.last_name }}
                  </p>
                </template>
                <template #option="{ props }">
                  <div style="display: grid; grid-template-columns: 50% 50%">
                    <p class="overflow-text-users-table">
                      {{ props.option.first_name }}
                    </p>
                    <p class="overflow-text-users-table">
                      {{ props.option.last_name }}
                    </p>
                  </div>
                </template>
              </MultiSelectInput>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <ElLabel
                for-label="priorty"
                :title="$t('Priority')"
                :is-required-icon="true"
              />
              <select
                class="form-control"
                v-model="form.priority"
                :disabled="btnLoading"
              >
                <option value="low">1 {{ $t("Low") }}</option>
                <option value="normal">2 {{ $t("Normal") }}</option>
                <option value="high">3 {{ $t("High") }}</option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <ElLabel
                for-label="status"
                :title="$t('Status')"
                :is-required-icon="true"
              />
              <select
                class="form-control"
                v-model="form.status"
                :disabled="btnLoading"
              >
                <option value="new">
                  {{ $t("New") }}
                </option>
                <option value="open">
                  {{ $t("Open") }}
                </option>
                <option value="waiting-for-response">
                  {{ $t("Waiting For Response") }}
                </option>
                <option value="pending">
                  {{ $t("Pending") }}
                </option>
                <option value="resolved">
                  {{ $t("Resolved") }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <TextInput
                v-model="form.dueDate"
                type="date"
                :label="$t('Due Date')"
                :tooltipMessage="$t('Due Date')"
                :required="true"
                :key="form.dueDate"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <MultiSelectInput
                :showLabels="false"
                v-model="form.personWorkingOnTicket"
                :options="users"
                :key="form.personWorkingOnTicket"
                :multiple="false"
                :textLabel="$t('Person Working On Ticket')"
                trackBy="id"
                class=""
                moduleName="users"
                :search-param-name="'search'"
                :customLabel="customLabel"
                :disabled="btnLoading"
              >
                <template #beforeList>
                  <div
                    class="p-1"
                    style="
                      display: grid;
                      gap: 2px;
                      grid-template-columns: 25% 25% 50%;
                    "
                  >
                    <p class="font-bold">
                      {{ $t("First Name") }}
                    </p>
                    <p class="font-bold">
                      {{ $t("Last Name") }}
                    </p>
                  </div>
                </template>
                <template #singleLabel="{ props }">
                  <p>
                    {{ props.option.first_name }}
                    {{ props.option.last_name }}
                  </p>
                </template>
                <template #option="{ props }">
                  <div style="display: grid; grid-template-columns: 50% 50%">
                    <p class="overflow-text-users-table">
                      {{ props.option.first_name }}
                    </p>
                    <p class="overflow-text-users-table">
                      {{ props.option.last_name }}
                    </p>
                  </div>
                </template>
              </MultiSelectInput>
            </div>
          </div>
          <div class="col-md-12 mt-2">
            <div class="form-group">
              <label
                >{{ $t("Text") }}<span style="color: red">&nbsp;*</span></label
              >
              <MarkdownEditor
                :content="form.text || ''"
                @change="form.text = $event"
                :builder-images="[]"
                :editable="true"
              />
            </div>
          </div>

          <div class="col-md-12">
            <div class="dropzone">
              <vue-dropzone
                id="dropzone"
                ref="myDropzone"
                :options="dropzoneOptions"
              />
            </div>
            <showFiles
              :commentFiles="attachments"
              :showImages="true"
              @remove="deleteUpload"
            />
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-end mt-2">
          <loading-button @click="submit" :loading="btnLoading">
            <feather-icon class="mr-1" icon="PlusIcon" size="12" />
            <span>{{ $t("Create") + " " + $t("Ticket") }}</span>
          </loading-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import vueDropzone from "vue2-dropzone";
import ElLabel from "@/components/ElLabel.vue";
import TextInput from "@/components/TextInput.vue";
import PageHeader from "@/components/PageHeader.vue";
import MultiSelectInput from "@/components/MultiSelectInput.vue";
import showFiles from "@/components/ticketSystem/show-files.vue";
import MarkdownEditor from "@/components/MarkdownEditor.vue";
import LoadingButton from "@/components/LoadingButton.vue";

export default {
  components: {
    vueDropzone: vueDropzone,
    showFiles,
    ElLabel,
    TextInput,
    PageHeader,
    MultiSelectInput,
    MarkdownEditor,
    LoadingButton,
  },
  data() {
    return {
      ticketCreationForms: [],
      items: [
        {
          text: "Dental Twin",
          to: "/",
        },
        {
          text: "Tickets",
          to: "/tickets",
        },
        {
          text: this.$t("Create"),
          active: true,
        },
      ],
      areaList: [
        {
          id: "partner",
          name: "Partner",
        },
        {
          id: "customer",
          name: "Customer",
        },
        {
          id: "product",
          name: "Product",
        },
      ],
      editorOption: {
        theme: "snow",
        placeholder: this.$t("Insert text here..."),
      },
      editorConfig: {
        mode: "edit", // Set 'edit' mode by default
      },
      form: {
        title: "",
        status: "new",
        priority: "",
        assignee: [],
        text: "",
        dueDate: "",
        personWorkingOnTicket: null,
      },
      badgeOptions: [],
      attachments: [],
      submitted: false,
      btnLoading: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters("tickets", ["tickets"]),
    ...mapGetters("users", ["users"]),
    ...mapGetters(["showLoader"]),

    dropzoneOptions() {
      const component = this; // Capture the component context
      return {
        url: process.env.backEndUrl + "/api/convert-file-to-base64",
        autoProcessQueue: false,
        maxFiles: 5,
        accept: function (file, done) {
          done();
        },
        acceptedFiles:
          ".jpg,.jpeg,.png,.pdf,.csv,.doc,.docx,.ppt,.pptx,.xls,.xlsx",
        dictDefaultMessage: `<i class='fe-upload-cloud'></i>${this.$t(
          "Click or drag file here to upload"
        )}`,
        addRemoveLinks: true,
        init: function () {
          this.on("addedfile", function (file) {
            component.processFile(file); // Use the component context
          });
        },
      };
    },
  },
  methods: {
    customLabel(props) {
      return `${props?.first_name || ""} ${props?.last_name || ""}`;
    },
    async handleSelectionChange(id) {
      const response = await this.showBadges(id);
      this.badgeOptions = response?.data?.data ?? {};
    },
    deleteUpload(index) {
      this.attachments.splice(index, 1);
    },
    processFile(file) {
      let base64Content = "";

      var reader = new FileReader();
      reader.onload = (event) => {
        const fileName = file.name;
        const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);
        const fileExtension = fileName
          .substring(fileName.lastIndexOf(".") + 1)
          .toLowerCase();

        const dataUri = event.target.result;
        const parts = dataUri.split(",");
        if (parts.length === 2) {
          base64Content = parts[1];
          let file = {
            name: fileName,
            type: fileExtension,
            size: `${fileSizeMB} MB`,
            base64: base64Content,
          };
          this.attachments.push(file);
        }
      };
      reader.readAsDataURL(file);
      this.$refs.myDropzone.removeAllFiles();
    },
    async submit() {
      this.btnLoading = true;
      this.submitted = true;
      try {
        await this.$store.dispatch("tickets/create", {
          ...this.form,
          attachments: this.attachments,
          assignee: this.form.assignee.map((assignee) => assignee.id),
          personWorkingOn: this.form.personWorkingOnTicket?.id ?? "",
          isAdminPortal: true,
        });
        this.$router.push("/tickets");
      } catch (e) {
        console.log(e);
      } finally {
        this.btnLoading = false;
      }
      //   }
    },
  },
  async mounted() {
    this.$store.commit("showLoader", true);
    await this.$store.dispatch("users/list");
    this.$store.commit("showLoader", false);
  },
};
</script>

<style scoped>
li .v-md-editor__tooltip {
  display: none !important;
}
.hidden {
  display: none !important;
}
.tooltip-hidden {
  display: none !important;
}
:deep(.markdown-body) {
  height: 350px;
  background-color: transparent !important;
}
:deep(.dropzone) .dz-preview {
  z-index: 1 !important;
}
</style>
